import React from "react"
import useTranslations from "../../utils/useTranslations"
import FeaturesList from "../../components/featuresList"
import ComparisonPanels from "../../components/comparisonPanels"
import { CompatibilityContext } from "../../components/compatibilityContext";
import { KasperskyHOC } from "../../components/kasperskyHook"

import heroImgWebp from "../../images/products/heroes/ui-classic.webp"
import heroImg from "../../images/products/heroes/ui-classic.png"
import heroImgWebp2x from "../../images/products/heroes/ui-classic@2x.webp"
import heroImg2x from "../../images/products/heroes/ui-classic@2x.png"


import Button from "../../components/button"
import Logo from "../../components/logo"
import LocalizedLink from "../../components/localizedLink"
import backgrounds from "../../components/backgrounds"
import TipsFaq from "../../components/tipsFaq"

const heroImgAlt = "Download µTorrent Classic for Desktop"


class BittorrentClassicFree extends React.Component {

  handleClassicProClick(e, action, link) {
    this.context(e, action, 'Classic', ['Win'], link)
  }

  render(){

    const { text, pageName, unlocalizedPath, locale } = this.props.pageContext
    const t = useTranslations(text)
    const {isTR, isKO, kasperskyBanner} = this.props.myHookValue;

    return (
      <>
        
        <div className="pt-md-2">

          <div className="container text-dark py-2">

            <div className="d-flex text-center mt-4 my-sm-4 justify-content-center">
              
              <div className="order-md-12 mt-md-4 px-md-0 d-flex flex-column">

                <Logo secondaryColor="black" tag="h1" productName="Classic" className="mb-4" fixedParentDistance={3}/>
                {/*<img src={heroImg} alt={heroImgAlt} className="img-fluid d-block d-md-none mb-4"></img>*/}

                <picture className="d-flex justify-content-center align-items-start">
                  <source srcSet={`${heroImgWebp} 1x, ${heroImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${heroImg} 1x, ${heroImg2x} 2x`} src={heroImg} alt={heroImgAlt} className="img-fluid d-block d-md-none mb-4"></img>
                </picture>

                <h1 className="text-size-24 text-spacing-1 font-weight-normal">{t(`Get the world's #1 desktop torrent client.`)}</h1>
                <h2 className="text-size-16 text-spacing-1 font-weight-normal my-3">{t(`Fast, bulk torrent downloads from the desktop`)}</h2>
                
                <div>
                  <Button  href={`${unlocalizedPath}#comparison-panels`} id="products-win-classic-free-hero-cta" className="btn-primary text-spacing-1 mt-3 mt-md-3 mb-4 button-filled text-spacing-1">{t(`Free Download`)}</Button>
                </div>

              </div>
              
            </div>
            <div className="col-md-12 d-none d-md-block mb-5 order-md-2">
                {/*<img src={heroImg} alt={heroImgAlt} className="img-fluid d-none d-md-block"></img>*/}

                <picture className="d-flex justify-content-center">
                  <source srcSet={`${heroImgWebp} 1x, ${heroImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${heroImg} 1x, ${heroImg2x} 2x`} src={heroImg} alt={heroImgAlt} className="img-fluid d-none d-md-block shadow-lg rounded-xl"></img>
                </picture>

              </div>
          </div>
        </div>

        <div className="container-fluid position-relative overflow-hidden">
          <div className="container z-2">
            <div className="container d-flex justify-content-center align-items-center py-2 px-0 my-4 mt-sm-5">
                <div className="col-12 order-md-1 d-flex flex-column justify-content-center align-items-center">
                  <h2 className="font-weight-bold text-md-left feature-list-title">{t(`Features`)}</h2>
                  <p className="font-weight-normal mb-3 mt-3 text-center text-md-left feature-list-title-description">{t(`µTorrent Classic Torrent Client Features`)}</p>              
                </div>
              </div>
            <div className = "index-features-list m-auto pb-5">
              <FeaturesList text={text} pageName={pageName}/>
            </div>
          </div>
        </div>

        <div className="container-fluid position-relative overflow-hidden panel-bg-3" style={backgrounds.lightBg}>
      		<div className="container my-5 z-2">
            <p className="text-center font-weight-bold feature-list-title mb-3">{t(`Compare`)}</p>
            <h2 className="text-center mb-5 text-size-24 font-weight-normal">
                {t(`Compare µTorrent Classic Versions`)}
            </h2>

            {
              kasperskyBanner
            }

            <ComparisonPanels pageContext={{ "text": text, "pageName":pageName, "isKasperskyTR": isTR, "isKasperskyKO": isKO }} >
              <p className="text-center mt-5">
                <LocalizedLink to="/pro/" id="products-win-classic-free-learnmore-premium" className="font-weight-bold">
                  {t(`Learn more`)}
                </LocalizedLink> {t(`about µTorrent Classic premium products`)}
              </p>
            </ComparisonPanels>
          </div>
        </div>

        <TipsFaq type="faq" header={t(`FAQ`)} subheader={t(`µTorrent Classic Frequently Asked Questions`)}>
          <div className="col-12 px-0">
            <p className="product-faq-question">{t(`What makes µTorrent Classic the most popular torrent client?`)}</p>
            <p className="product-faq-answer">{t(`Launched over a decade ago by Ludvig Strigeus, µTorrent (also known as micro torrent) became popular due to its tiny file size and the small memory footprint required to run on Windows. Since then, µTorrent has been developed on a continuous basis to provide a deep feature set perfectly suited for automating torrent downloads, managing bandwidth and data usage, customizing the interface and more. Ideal for experienced users, it’s the best Windows 10 torrent client and supports Windows versions all the way back to XP, Vista, 7 and 8.`)}</p>
            <p className="product-faq-question">{t(`I can’t decide between µTorrent Classic or µTorrent Web. Can you help me choose?`)}</p>
            <p className="product-faq-answer">{t(`µTorrent Classic is a desktop-based torrent client that is packed with features to enable both download automation and remote connectivity to your torrent client from anywhere in the world. It’s ideal if you are looking to download different types of files frequently, as the automation features can help streamline the process. Also, µTorrent Classic is a torrent client that you can remotely access any time, from anywhere in the world, provided that your computer at home is turned on and µTorrent Classic is running. The torrent client is more ideal for experienced users given the variety of customization options, however, is still perfectly suitable for beginners. Since the standard settings work just fine, you can still download torrents without needing to setup or configure the software.`)}</p>
            <p className="product-faq-answer">{t(`If the files you download are video or audio based, and you like the ability to play them while you download, µTorrent Web is a great choice. Unlike µTorrent Classic, which is a desktop-based torrent client, µTorrent Web is an online torrent downloader that installs into your favorite web browser. The interface features a prominent media player that can play your files near-instantly, while they download, or you can play them when you’re offline. The online torrent downloader has many advantages over the µTorrent Classic desktop-based software, including a simpler interface, less settings, and an experience that is integrated more tightly with your online browsing.`)}</p>
          </div>
        </TipsFaq>


      </>
    )

  }
}

BittorrentClassicFree.contextType = CompatibilityContext;

// export default BittorrentClassicFree
export default KasperskyHOC(BittorrentClassicFree)
